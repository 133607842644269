import onLoadAsync from '../on_load_async.js';

onLoadAsync(() => {
  // Show main menu when clicking on the burger
  document.querySelectorAll('.js-navigation-burger-menu').forEach((menu) => {
    menu.addEventListener('click', (event) => {
      event.preventDefault(); // Avoid going to the top of the page when opening the menu
      const mobileMainMenu = document.querySelector('.js-navigation-mobile-menu');
      mobileMainMenu.classList.add('active');
      // hide page scrollbars while the menu are displayed
      document.body.style.overflow = 'hidden';
    });
  });

  // Close all menus when clicking on the close button
  document.querySelectorAll('.js-navigation-close-menu').forEach((closeMenu) => {
    closeMenu.addEventListener('click', () => {
      document.querySelectorAll('nav.navigation-mobile').forEach((overlay) => {
        overlay.classList.remove('active');
      });
      // unhide page scrollbars while the menu are displayed
      document.body.style.overflow = 'visible';
    });
  });
});
